import { ApolloQueryResult } from '@apollo/client'
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import BigNumber from 'bignumber.js'
import { clientNftApi } from 'clients'
import { DEFAULT_CHAIN_ID } from 'constants/index'
import { NftCollection } from 'modules/apecoin-staking/types/graphql'
import { ApecoinPriceFeedAbi, BendCoinPoolAbi, BendNftPoolAbi } from 'modules/apecoin-staking-v2/abis'
import { APECOIN_PRICE_FEED, BEND_COIN_POOL_ADDRESS, BEND_NFT_POOL_ADDRESS } from 'modules/apecoin-staking-v2/constants'
import { APECOIN_ADDRESS_MAINNET, BAKC_ADDRESS, BAYC_ADDRESS, MAYC_ADDRESS } from 'modules/bend/constants'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'modules/multicall-module/constants'
import { ChainId, getContract } from 'modules/wallet-module'

import { COLLECTIONS_FLOORPRICE_QUERY } from './graphql'

export const getApeStakingV2TvlData = async ({
  provider: providerInjected
}: {
  provider: JsonRpcProvider | null | undefined
}): Promise<{
  floorPrice: {
    bayc: BigNumber
    mayc: BigNumber
    bakc: BigNumber
  }
  totalStakedNft: {
    bayc: BigNumber
    mayc: BigNumber
    bakc: BigNumber
    apeCoin: BigNumber
  }
  assetPriceETH: {
    apeCoin: BigNumber
  }
}> => {
  let provider: JsonRpcProvider | null | undefined = providerInjected

  if (!provider) {
    provider = new JsonRpcProvider(String(process.env.NEXT_PUBLIC_NETWORK_URL))
  }

  const [{ baycFloorPrice, maycFloorPrice, bakcFloorPrice }, { totalStakedBayc, totalStakedMayc, totalStakedBakc, totalDepositedApeCoin }, apeCoinPriceETH] =
    await Promise.all([getApeStakingV2CollectionFloorPrices(), getApeStakingV2totalStakedNfts({ provider }), apeStakingV2apeCoinEthPriceFeed({ provider })])

  return {
    floorPrice: {
      bayc: baycFloorPrice,
      mayc: maycFloorPrice,
      bakc: bakcFloorPrice
    },
    totalStakedNft: {
      bayc: totalStakedBayc,
      mayc: totalStakedMayc,
      bakc: totalStakedBakc,
      apeCoin: totalDepositedApeCoin
    },
    assetPriceETH: {
      apeCoin: apeCoinPriceETH
    }
  }
}

const getApeStakingV2CollectionFloorPrices = async () => {
  try {
    const {
      data: { nftCollections }
    }: ApolloQueryResult<{ nftCollections: Array<NftCollection> }> = await clientNftApi.query({
      query: COLLECTIONS_FLOORPRICE_QUERY,
      variables: {
        address_in: [BAYC_ADDRESS, MAYC_ADDRESS, BAKC_ADDRESS]
      }
    })

    const baycFloorPrice = new BigNumber(
      nftCollections.find(collection => collection.address?.toLowerCase() === BAYC_ADDRESS.toLowerCase())?.floorPrice || 0
    ).dividedBy(1e18)
    const maycFloorPrice = new BigNumber(
      nftCollections.find(collection => collection.address?.toLowerCase() === MAYC_ADDRESS.toLowerCase())?.floorPrice || 0
    ).dividedBy(1e18)
    const bakcFloorPrice = new BigNumber(
      nftCollections.find(collection => collection.address?.toLowerCase() === BAKC_ADDRESS.toLowerCase())?.floorPrice || 0
    ).dividedBy(1e18)

    return {
      baycFloorPrice,
      maycFloorPrice,
      bakcFloorPrice
    }
  } catch (error) {
    console.error({ error })
    return {
      baycFloorPrice: new BigNumber(0),
      maycFloorPrice: new BigNumber(0),
      bakcFloorPrice: new BigNumber(0)
    }
  }
}

const getApeStakingV2totalStakedNfts = async ({ provider }: { provider: JsonRpcProvider }) => {
  // const BendStakeManager = getContract(BEND_STAKE_MANAGER_ADDRESS, BendStakeManagerAbi, provider as Web3Provider)
  const BendNftPoolContract = getContract(BEND_NFT_POOL_ADDRESS, BendNftPoolAbi, provider as Web3Provider)
  const BendCoinPoolContract = getContract(BEND_COIN_POOL_ADDRESS, BendCoinPoolAbi, provider as Web3Provider)
  const MulticallContract = getContract(MULTICALL_NETWORKS[Number(DEFAULT_CHAIN_ID) as ChainId], MULTICALL_ABI, provider as Web3Provider)
  try {
    // const { _hex: totalStakedApeCoin } = await BendStakeManager?.totalStakedApeCoin()
    const { _hex: totalDepositedApeCoin } = await BendCoinPoolContract?.totalAssets()

    const poolStateFragment = BendNftPoolContract.interface.getFunction('getPoolStateUI(address nft_)')
    const poolStateChunk = [
      {
        address: BEND_NFT_POOL_ADDRESS,
        data: BendNftPoolContract?.interface?.encodeFunctionData(poolStateFragment, [BAYC_ADDRESS])
      },
      {
        address: BEND_NFT_POOL_ADDRESS,
        data: BendNftPoolContract?.interface?.encodeFunctionData(poolStateFragment, [MAYC_ADDRESS])
      },
      {
        address: BEND_NFT_POOL_ADDRESS,
        data: BendNftPoolContract?.interface?.encodeFunctionData(poolStateFragment, [BAKC_ADDRESS])
      }
    ]

    const [baycDecoded, maycDecoded, bakcDecoded] = (await MulticallContract?.aggregate(poolStateChunk.map(obj => [obj.address, obj.data])))?.returnData || []

    const { _hex: totalStakedBayc } = BendNftPoolContract?.interface?.decodeFunctionResult(poolStateFragment, baycDecoded)?.totalNfts
    const { _hex: totalStakedMayc } = BendNftPoolContract?.interface?.decodeFunctionResult(poolStateFragment, maycDecoded)?.totalNfts
    const { _hex: totalStakedBakc } = BendNftPoolContract?.interface?.decodeFunctionResult(poolStateFragment, bakcDecoded)?.totalNfts

    return {
      totalStakedBayc: new BigNumber(totalStakedBayc),
      totalStakedMayc: new BigNumber(totalStakedMayc),
      totalStakedBakc: new BigNumber(totalStakedBakc),
      totalDepositedApeCoin: new BigNumber(totalDepositedApeCoin.toString()).dividedBy(1e18)
    }
  } catch (error) {
    console.log({ error })
    return {
      totalStakedBayc: new BigNumber(0),
      totalStakedMayc: new BigNumber(0),
      totalStakedBakc: new BigNumber(0),
      totalDepositedApeCoin: new BigNumber(0)
    }
  }
}

const apeStakingV2apeCoinEthPriceFeed = async ({ provider }: { provider: any }) => {
  try {
    if (DEFAULT_CHAIN_ID === '1') {
      const ApeCoinPriceFeedContract = getContract(APECOIN_PRICE_FEED, ApecoinPriceFeedAbi, provider as Web3Provider)
      const { _hex: latestAnswer } = await ApeCoinPriceFeedContract.latestAnswer()

      const price = new BigNumber(latestAnswer.toString()).dividedBy(1e18)

      if (price.eq(0) || price.isNaN()) {
        return await getCoingeckoApeCoinPrice()
      }

      return new BigNumber(latestAnswer.toString()).dividedBy(1e18)
    }

    return await getCoingeckoApeCoinPrice()
  } catch (error) {
    console.error({ error })
    return new BigNumber(0)
  }
}

const getCoingeckoApeCoinPrice = async () => {
  try {
    const request = await fetch(`https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${APECOIN_ADDRESS_MAINNET}&vs_currencies=USD`)
    const response = await request.json()

    return new BigNumber(response[APECOIN_ADDRESS_MAINNET.toLowerCase()].usd || 0)
  } catch (error) {
    console.error({ error })
    return new BigNumber(0)
  }
}
